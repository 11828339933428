import { useEffect } from 'react'

// Callback function when burst is available or change
export default (callback, events = []) => {
  useEffect(() => {
    if (!events.some(e => e && e.isMock)) {
      callback(events)
    }
  }, events)
}
