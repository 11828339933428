import generateUniqueId from 'generate-unique-id'
import { sleep, addAdhocCallback, isFunction } from './util'
import Constants from '@emerald-works/constants'
import debug from 'debug'

const logger = debug(`${Constants.COMPONENT_EVENT_BUS_CLIENT}:event-manager`)
const noop = () => {}
const types = [
  'onStart',
  'onSuccess',
  'onStop',
  'onUnregister',
  'onError',
  'onUnregister',
  'onSubscribe',
  'onUnsubscribe',
  'onAck'
]

export default class EventManager {
  constructor ({
    connection,
    canSubscribe = true,
    shouldTimeout = true,
    subscribeOnTrigger = false,
    subscribeOnInit = false,
    eventName,
    onStart = noop,
    onSuccess = noop,
    onStop = noop,
    onError = noop,
    onUnregister = noop,
    onSubscribe = noop,
    onUnsubscribe = noop,
    onAck = noop
  }) {
    this.eventName = eventName
    this.key = generateUniqueId()
    this.canSubscribe = canSubscribe
    this.shouldTimeout = shouldTimeout
    this.subscribeOnInit = subscribeOnInit
    this.subscribeOnTrigger = subscribeOnTrigger
    this.hasBeenTriggered = false
    this.contextId = null

    this.listeners = {
      onStart,
      onSuccess,
      onStop,
      onError,
      onAck,
      onUnregister,
      onSubscribe,
      onUnsubscribe
    }
    this.promiseListeners = {}
    this.promises = {
      onSubscribe: new Promise((resolve, reject) => {
        this.promiseListeners.onSubscribe = resolve
        this.promiseListeners.onError = reject
      }),
      onSuccess: new Promise((resolve, reject) => {
        this.promiseListeners.onSuccess = resolve
        this.promiseListeners.onError = reject
      }),
      onUnsubscribe: new Promise((resolve, reject) => {
        this.promiseListeners.onUnsubscribe = resolve
        this.promiseListeners.onError = reject
      }),
      onUnregister: new Promise((resolve, reject) => {
        this.promiseListeners.onUnregister = resolve
        this.promiseListeners.onError = reject
      })
    }

    logger('eventmanager this %o', this)
    this.connection = connection
    if (this.subscribeOnInit) {
      this.subscribeToEvent()
    }
  }

  cancelTimeout () {
    logger('cancelTimeout')
    this.shouldTimeout = false
  }

  subscribeToEvent (payload, requestId) {
    if (this.canSubscribe) {
      const json = {
        subscribeTo: this.eventName,
        eventName: 'subscribe',
        payload,
        app: this.connection.app,
        key: this.key,
        requestId: requestId || generateUniqueId()
      }
      logger('subscribeToEvent json %j', json)
      this.connection.sendJson(json)
      // sleep for 150 ms to avoid dynamo propagation mismatch
      sleep(10)
    }
  }

  callListeners (type, params) {
    logger('callListeners listeners %o', this.listeners)
    const typeListeners = this.listeners[type]
    if (Array.isArray(typeListeners)) {
      for (const listener of typeListeners) {
        listener(params)
      }
    } else if (isFunction(typeListeners)) {
      typeListeners(params)
    }
    const promiseListeners = this.promiseListeners[type]
    if (promiseListeners) {
      promiseListeners(params)
    }
  }

  trigger (payload) {
    const requestId = generateUniqueId()
    logger('trigger payload %j requestId %j', payload, requestId)
    if (!this.hasBeenTriggered && this.subscribeOnTrigger) {
      this.subscribeToEvent(payload, requestId)
      if (payload) this.contextId = payload.id
    }
    this.hasBeenTriggered = true

    this.callListeners('onStart', this)

    this.connection.sendJson({
      eventName: this.eventName,
      key: this.key,
      payload,
      requestId
    })

    setTimeout(() => {
      if (this.shouldTimeout) {
        console.log('Event timed out', this)
        this.callListeners('onError', { message: 'Service Unavailable, it was not possible to reach our servers, try again later or contact support.' })
        this.callListeners('onStop', this)
      }
    }, this.connection.opts.triggerTimeout)

    types.forEach(type => {
      this.promises[type] = new Promise((resolve, reject) => {
        this.promiseListeners[type] = resolve
        this.promiseListeners.onError = reject
      })
    })
    if (this.eventName === 'disconnect') {
      this.connection.disconnect()
    } else {
      if (this.connection.isConnected() === Constants.CONNECTION_SIGNAL_STATUS_CLOSED) {
        this.connection.reconnect()
      }
    }
    return {
      promises: this.promises
    }
  }

  // Method
  unregister () {
    if (!this.subscribeOnInit && !this.subscribeOnTrigger) return
    if (this.subscribeOnTrigger && !this.hasBeenTriggered) return

    const json = {
      unsubscribeTo: this.eventName,
      eventName: 'unsubscribe',
      payload: { key: this.key, unsubscribeTo: this.eventName, contextId: this.contextId },
      app: this.connection.app,
      key: this.key,
      contextId: this.contextId
    }
    logger('unregister json %j connection %j', json, this.connection)
    if (this.connection) {
      this.connection.sendJson(json)
    }
    this.callListeners('onUnregister')
  }

  _registerAdhocListener (callback, lifecycleEvent) {
    if (isFunction(callback)) {
      this.listeners[lifecycleEvent] = addAdhocCallback(
        this.listeners[lifecycleEvent],
        callback
      )
    } else {
      logger('Adhoc listeners must be functions')
    }
  }

  registerAdhocOnSuccessListener (listener) {
    this._registerAdhocListener(listener, 'onSuccess')
  }

  registerAdhocOnStartListener (listener) {
    this._registerAdhocListener(listener, 'onStart')
  }

  registerAdhocOnStopListener (listener) {
    this._registerAdhocListener(listener, 'onStop')
  }

  registerAdhocOnErrorListener (listener) {
    this._registerAdhocListener(listener, 'onError')
  }

  registerAdhocOnAckListener (listener) {
    this._registerAdhocListener(listener, 'onAck')
  }

  registerAdhocOnSubscribeListener (listener) {
    this._registerAdhocListener(listener, 'onSubscribe')
  }

  registerAdhocOnUnsubscribeListener (listener) {
    this._registerAdhocListener(listener, 'onUnsubscribe')
  }

  registerAdhocOnUnregisterListener (listener) {
    this._registerAdhocListener(listener, 'onUnregister')
  }
}
