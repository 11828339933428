import Constants from '@emerald-works/constants'
import * as EventBus from '@emerald-works/event-bus-client'
import React, { useEffect, useState } from 'react'
import { SocketConnectionContext } from './context'
import useEventInitialiser from './hooks/use-event-initialiser'
import useEvent from './hooks/use-event'
import useEventsOnViewLoad from './hooks/use-events-on-view-load'
import { objectToQueryString } from './util'
import Auth from '@emerald-works/react-auth'

const noop = () => {}

const InitialiserRegister = ({ children, initialisers }) => {
  // Setup initialisers listeners
  // Every function that is trigger on the "connection" event is an initialiser.
  // Data will be send to frontend if there is any listeners for that initialiser.
  useEventInitialiser(initialisers)
  return children
}

const EventBusProvider = ({
  eventBusURL,
  namespace,
  useAuthProvider = false,
  fetchUserProfile = false,
  LoadingComponent,
  waitForConnection = false,
  connectionParams,
  onOpen = noop,
  onConnectionChange = noop,
  onReconnect = noop,
  onMaximum = noop,
  onClose = noop,
  onError = noop,
  initialisers = [],
  options,
  ...props
}) => {
  const session = Auth?.useSession() || { generateEventBusToken: noop }

  // Create connected status state variable.
  // This is used to track the websocket connection state through GamaRayContext.
  const [isConnected, setIsConnected] = useState(false)
  const [connectionStatus, setConnectionStatus] = useState(
    Constants.CONNECTION_SIGNAL_STATUS_RED
  )
  const [connection, setConnection] = useState(false)
  const [connectedEventBusURL, setConnectedEventBusURL] = useState()
  const [connectedNamespace, setConnectedNamespace] = useState()

  // Open connection when component first render or on every eventBusURL and namespace change.
  // Once the component in unmounted: close the connection
  useEffect(() => {
    if (connection) {
      if (
        eventBusURL !== connectedEventBusURL ||
        namespace !== connectedNamespace
      ) {
        connection.reloadConnection()
        handleEventBusConnection()
      }
      return () => {
        connection.disconnect()
      }
    } else {
      handleEventBusConnection()
    }
  }, [
    eventBusURL,
    namespace,
    connection,
    connectedEventBusURL,
    connectedNamespace
  ])

  const [getProfileEvent] = useEvent([
    {
      eventName: Constants.GET_CONTEXT_USER_PROFILE_EVENT,
      onSuccess: userProfile => session.updateSessionContext({ userProfile })
    }
  ])

  useEventsOnViewLoad(() => fetchUserProfile && getProfileEvent.trigger(), [
    getProfileEvent
  ])

  // handle EventBus connection events.
  const handleEventBusConnection = () => {
    setConnectedEventBusURL(eventBusURL)
    setConnectedNamespace(namespace)
    setConnection(
      EventBus.connect({
        eventBusURL: async attempt => {
          try {
            // debugger
            const token = useAuthProvider
              ? await session.generateEventBusToken()
              : null
            // debugger
            const params = objectToQueryString({
              ...connectionParams,
              attempt,
              token
            })
            return `${eventBusURL}?${params}`
          } catch (e) {
            console.log(e)
          }
        },
        namespace,
        options,
        onConnectionChange: status => {
          if (status === Constants.CONNECTION_SIGNAL_STATUS_GREEN) {
            setIsConnected(true)
          } else {
            setIsConnected(false)
          }
          setConnectionStatus(status)
          onConnectionChange(status)
        },
        onClose: () => {
          setIsConnected(false)
          onClose()
        },
        onOpen: () => {
          onOpen()
        },
        onReconnect: () => {
          onReconnect()
        },
        onMaximum,
        onError
      })
    )
  }

  const reloadConnection = () => {
    if (connection) {
      connection.reloadConnection()
      handleEventBusConnection()
    }
  }

  const reconnect = () => {
    if (connection) {
      connection.reconnect()
    }
  }

  // Render application if connection is opened or if user doesn't want to
  // wait for the connection.
  // If the user doesn't wait for the connection, is up to the user to check the connection status
  // using the context before blasting anything. Otherwise GammaRay will throw an error.
  const childrenElement = () => (
    <SocketConnectionContext.Provider
      value={{
        isConnected,
        reloadConnection,
        reconnect,
        connection,
        connectionStatus
      }}
    >
      <InitialiserRegister initialisers={initialisers}>{props.children}</InitialiserRegister>
    </SocketConnectionContext.Provider>
  )

  return isConnected || !waitForConnection
    ? childrenElement()
    : LoadingComponent
}

export default EventBusProvider
