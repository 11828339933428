import SocketManager from './socket-manager'
import debug from 'debug'

const logger = debug('EventManager:connection')

export const connect = ({
  eventBusURL,
  namespace,
  onOpen,
  onConnectionChange,
  onReconnect,
  onMaximum,
  onClose,
  onError,
  options
}) => {
  const connection = new SocketManager()
  logger('connecting', eventBusURL, namespace, options)
  connection.connect({
    eventBusURL,
    namespace,
    onOpen,
    onConnectionChange,
    onReconnect,
    onMaximum,
    onClose,
    onError,
    options
  })
  return connection
}
