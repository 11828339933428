import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import useInstance from '@use-it/instance'
import useForceUpdate from 'use-force-update'
import {
  addWorkingStatusCallbacks,
  normaliseEventManager,
  createEventManagerMock
} from '../util'
import useEventManagerWorkingStatus from './use-event-manager-working-status'
import { useConnectionContext } from '../context'

export default ({ eventParams = [], isInitialiser = false }) => {
  // create a unique object instance ref for the entire component lifecycle
  // check if busrt is already defined in the object instance ref before
  // creating new bursts.
  // React refs are only persisted after the first render. This is why we need to force
  // a component update after the ref is set so the component using this hook receives
  // the created burst.
  // If we put the create burst code outside the useEffect multiples burst are going to be created.
  // until the first render happens.
  // More info
  // https://medium.com/@teh_builder/ref-objects-inside-useeffect-hooks-eb7c15198780
  const context = useConnectionContext()
  const dispatch = useDispatch()
  const self = useInstance()
  const forceUpdate = useForceUpdate()
  const [
    eventManagersWorkingState,
    updateEventManagersWorkingState
  ] = useEventManagerWorkingStatus([])

  // Run this effect after the first render.
  // So the ref instance value will be persisted
  useEffect(() => {
    if (!context?.connection) {
      return () => {
        if (self.eventManagers) {
          for (const eventManager of self.eventManagers) {
            eventManager.unregister()
          }
        }
      }
    }
    if (!self.eventManagers && eventParams.length > 0) {
      const normalisedEventManager = normaliseEventManager(
        eventParams,
        dispatch
      )
      self.eventManagers = normalisedEventManager.map(event => {
        const param = {
          ...addWorkingStatusCallbacks(
            event,
            event => {
              updateEventManagersWorkingState(event, true)
            },
            event => {
              updateEventManagersWorkingState(event, false)
            }
          ),
          isInitialiser
        }
        return context.connection.createEventManager(param)
      })
    }

    // since ref changes doesn't tigger react update, we need to force update the react compoennt
    // so it gets the created bursts
    forceUpdate()

    // unregister burst when component unmount
    return () => {
      if (self.eventManagers) {
        for (const eventManager of self.eventManagers) {
          eventManager.unregister()
        }
      }
    }
  }, [context, context?.connection])

  // Update burst isWorking value
  if (self.eventManagers) {
    for (const eventManager of self.eventManagers) {
      eventManager.isWorking = Boolean(
        eventManagersWorkingState[eventManager.key]
      )
    }
  }

  return self.eventManagers || createEventManagerMock(eventParams)
}
